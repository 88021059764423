import React, { useMemo } from 'react';
import { InputStyle, InputStyleWrapper, Select } from '@getvim/atomic-ui';

import './styles.less';

interface SelectDropdownProps {
  options: any[];
  selectedOption: any;
  label: string;
  valueField: string;
  labelField: string;
  onChange: (value: any) => void;
  onSearch: (search?: any) => void;
  onDropdownClose: (state: boolean) => void;
  selectRef?: any;
  isLoading?: boolean;
  clearable?: boolean;
  searchable?: boolean;
  sortOptions?: boolean;
}

const SelectDropdown = ({
  selectRef,
  options,
  isLoading,
  selectedOption,
  labelField,
  valueField,
  label,
  onDropdownClose,
  onSearch,
  searchable = true,
  sortOptions = true,
  onChange,
}: SelectDropdownProps) => {
  const sortedOptions = useMemo(
    () =>
      [...options].sort((a, b) => {
        if (a[labelField].toLowerCase() < b[labelField].toLowerCase()) {
          return -1;
        }
        if (a[labelField].toLowerCase() > b[labelField].toLowerCase()) {
          return 1;
        }

        return 0;
      }),
    [options, labelField],
  );

  return (
    <InputStyleWrapper className="select-dropdown-wrapper" inputStyle={InputStyle.medium}>
      <Select
        id="select-dropdown"
        selectRef={selectRef}
        name={valueField}
        valueField={valueField}
        labelField={labelField}
        label={label}
        options={sortOptions ? sortedOptions : options}
        searchFn={onSearch}
        onChange={onChange}
        values={[selectedOption]}
        loading={isLoading}
        searchable={searchable}
        setTouched={onDropdownClose}
        keepSelectedInList
        closeOnSelect={false}
        withDebounce
        showChevronIcon
      />
    </InputStyleWrapper>
  );
};

export default SelectDropdown;
