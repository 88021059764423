import { Button } from '@getvim/atomic-ui';
import React, { useCallback, useEffect, useState } from 'react';
import ApplicationOverridesList from './ApplicationOverridesList';
import EditApplicationOverride from './EditApplicationOverride';
import './style.less';
import { AppToOverride, StoredAppOverrides } from './types';

const VIM_CONNECT_SDK_STORAGE_KEY = 'vim_connect_sdk_storage';
const localStorageKey = 'appOverrides';

type Props = {
  onClose: () => void;
};

const emptyApp: AppToOverride = {
  appId: '',
  version: '',
  url: '',
  tokenEndpoint: '',
  launchEndpoint: '',
};

const getLocalStorage = () => {
  try {
    return JSON.parse(localStorage.getItem(VIM_CONNECT_SDK_STORAGE_KEY) ?? '{}');
  } catch {
    return {};
  }
};

export const updateSDKLocalStorage = (key: string, value: any) => {
  const fromLocalStorage = getLocalStorage();
  fromLocalStorage[key] = value;
  localStorage.setItem(VIM_CONNECT_SDK_STORAGE_KEY, JSON.stringify(fromLocalStorage));
};

export const updateAppOverride = ({ appId, ...override }: AppToOverride) => {
  const sdkStorage = getLocalStorage();

  sdkStorage[localStorageKey] = {
    ...(sdkStorage?.[localStorageKey] ?? {}),
    [appId]: override,
  };
  localStorage.setItem(VIM_CONNECT_SDK_STORAGE_KEY, JSON.stringify(sdkStorage));
};

const VimSettings: React.FC<Props> = ({ onClose }: Props) => {
  const [apps, setApps] = useState<StoredAppOverrides>(() => {
    return getLocalStorage()[localStorageKey] ?? {};
  });
  const [vimOsAccessToken, setVimOsAccessToken] = useState<string | undefined>(undefined);
  const [appToOverride, setAppToOverride] = useState<AppToOverride>(emptyApp);
  const [copyClicked, setCopyClicked] = useState(false);
  const onAppFieldChange = useCallback((key: keyof AppToOverride, value: string) => {
    setAppToOverride((prev) => ({ ...prev, [key]: value }));
  }, []);
  const setAppToEdit = useCallback(
    (appId: string) => {
      setAppToOverride({ appId, ...apps[appId] });
    },
    [apps],
  );
  const addApp = useCallback(() => {
    setApps((prev) => ({ ...prev, [appToOverride.appId]: appToOverride }));
    setAppToOverride(emptyApp);
  }, [appToOverride]);
  const deleteApp = useCallback((appId: string) => {
    setApps((prev) => {
      const newApps = { ...prev };
      delete newApps[appId];
      return newApps;
    });
  }, []);
  const cancel = useCallback(() => {
    setAppToOverride(emptyApp);
    setApps(getLocalStorage()[localStorageKey] ?? {});
  }, []);
  const save = useCallback(() => {
    updateSDKLocalStorage(localStorageKey, apps);
    window.location.reload();
  }, [apps]);
  const isNewApp = !apps[appToOverride.appId];

  useEffect(() => {
    const intervalId = setInterval(() => {
      const sdkLocalStorage = getLocalStorage();

      const userMail = sdkLocalStorage.sdkUserInfo?.email;
      for (const [key, value] of Object.entries(sdkLocalStorage.vimTokens ?? {})) {
        if (key.startsWith(userMail)) {
          setVimOsAccessToken((value as any)?.accessToken);
          break;
        }
      }
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const copyAccessToken = useCallback(() => {
    if (vimOsAccessToken) {
      window.navigator.clipboard.writeText(vimOsAccessToken);
      setCopyClicked(true);
      setTimeout(() => {
        setCopyClicked(false);
      }, 5000);
    }
  }, [vimOsAccessToken]);

  return (
    <div className="vim-settings">
      <div className="top-bar">
        <div className="title">Vim settings</div>
        <Button buttonType="link" onClick={onClose}>
          <span className="icon-x-2" />
        </Button>
      </div>
      <div className="content">
        <div className="content-section">
          <div className="section-title">
            <div>Application authentication &nbsp;</div>
            <a
              href="https://docs.getvim.com/vim-os-js/getting-started.html#testing-the-authentication-flow"
              target="_blank"
              rel="noreferrer"
              className="section-title-url"
            >
              Learn more
            </a>
          </div>
          <span className="section-text">
            You can use this access token (valid for 30 minutes) to test your application
            authentication flow separately.
          </span>

          {vimOsAccessToken === undefined && (
            <span className="section-text">
              You don't have any access token yet, Vim OS hasn't logged in yet.
            </span>
          )}
          <Button
            buttonType="small"
            disabled={vimOsAccessToken === undefined}
            bgColor="white"
            onClick={copyAccessToken}
          >
            {copyClicked ? (
              <>
                <span>Copied</span>&nbsp;&nbsp;
                <span className="icon-check" />
              </>
            ) : (
              <span>Copy Access Token</span>
            )}
          </Button>

          <div className="section-title">
            <div>Override app store url &nbsp;</div>
            <a
              href="https://docs.getvim.com/platform/testing.html#testing-your-application-with-vim-sandbox-ehr"
              target="_blank"
              rel="noreferrer"
              className="section-title-url"
            >
              Learn more
            </a>
          </div>

          <span className="section-text">
            This allows you to choose the version or source url you want to use for testing your app
          </span>
          <EditApplicationOverride
            addApp={addApp}
            appToOverride={appToOverride}
            updateApp={onAppFieldChange}
            isNewApp={isNewApp}
          />
          <div className="divider" />
          <div className="application-overrides-list-container">
            <ApplicationOverridesList
              existingOverrides={apps}
              setAppToEdit={setAppToEdit}
              deleteApp={deleteApp}
            />
          </div>
        </div>
      </div>
      <div className="commit-buttons align-right">
        <Button buttonType="small" bgColor="white" onClick={cancel}>
          Cancel
        </Button>
        <Button buttonType="small" onClick={save}>
          Save and Reload Page
        </Button>
      </div>
    </div>
  );
};

export default VimSettings;
