import dayjs from 'dayjs';
import {
  InputSchema,
  InputOptionalSchema,
  EmailSchemaOptional,
  PhoneNumberSchema,
  PhoneNumberSchemaOptional,
  SelectInputSchema,
  SelectInputOptionalSchema,
  UrlSchema,
  DateOfBirthDataVerificationSchema,
} from '../../schemas';
import createValidator from '../../utils/createValidator';
import { CleanInput } from '../clean-input';
import { MuiDatePicker } from '../mui-date-picker';
import { LocationSelectWrapper } from '../location-select-wrapper';
import { SelectTypeahead } from '../select-typeahead';

const MRN_OR_MEMBER_ID_ERROR = `Either memberId or mrn shouldn't be empty`;

export const fields = {
  firstName: {
    name: 'firstName',
    field: 'firstName',
    component: CleanInput,
    validate(value: any) {
      return createValidator(value, schema.input);
    },
  },
  lastName: {
    name: 'lastName',
    field: 'lastName',
    component: CleanInput,
    validate(value: any) {
      return createValidator(value, schema.input);
    },
  },
  middleName: {
    name: 'middleName',
    field: 'middleName',
    component: CleanInput,
    validate(value: any) {
      return createValidator(value, schema.inputOptional);
    },
  },
  insurer: {
    name: 'insurer',
    field: 'insurer',
    component: CleanInput,
    validate(value: any) {
      return createValidator(value, schema.inputOptional);
    },
  },
  memberId: {
    name: 'memberId',
    field: 'memberId',
    component: CleanInput,
    validate(value: any, canBeNull = true) {
      if (!value && !canBeNull) {
        return MRN_OR_MEMBER_ID_ERROR;
      }

      return createValidator(value, schema.inputOptional);
    },
  },
  externalId: {
    name: 'externalId',
    field: 'externalId',
    component: CleanInput,
  },
  mrn: {
    name: 'mrn',
    field: 'mrn',
    component: CleanInput,
    validate(value: any, canBeNull = true) {
      if (!value && !canBeNull) {
        return MRN_OR_MEMBER_ID_ERROR;
      }

      return createValidator(value, schema.inputOptional);
    },
  },
  dob: {
    name: 'dob',
    field: 'dob',
    // eslint-disable-next-line no-useless-escape
    component: MuiDatePicker,
    validate(value: any) {
      const systemTimezoneOffset = dayjs().utcOffset();
      const selectedDate = dayjs(value).subtract(systemTimezoneOffset, 'minute');

      return createValidator(selectedDate.toDate(), schema.dob);
    },
  },
  phoneNumber: {
    name: 'phoneNumber',
    field: 'phoneNumber',
    component: CleanInput,
    validate(value: any) {
      const getValue = () => {
        if (value) {
          return { ...value, number: value.number?.replace(/\D/g, '') };
        }
        return undefined;
      };
      return createValidator(getValue(), schema.phoneNumber);
    },
  },
  homePhoneNumber: {
    name: 'homePhoneNumber',
    field: 'homePhoneNumber',
    component: CleanInput,
    validate(value: any) {
      const getValue = () => {
        if (value?.number) {
          return { ...value, number: value.number?.replace(/\D/g, '') };
        }
        return undefined;
      };
      return createValidator(getValue(), schema.phoneNumberOptional);
    },
  },
  email: {
    name: 'email',
    field: 'email',
    component: CleanInput,
    validate(value: any) {
      return createValidator(value, schema.emailInputOptional);
    },
  },
  pcp: {
    name: 'pcp',
    field: 'pcp',
    component: SelectTypeahead,
    validate(value: any) {
      const getValue = () => {
        if (value) {
          return typeof value === 'string' ? { pcp: value } : value;
        }
        return undefined;
      };

      return createValidator(getValue(), schema.selectInputOptional);
    },
  },
  location: {
    name: 'location',
    field: 'location',
    component: LocationSelectWrapper,
    validate(value: any) {
      return createValidator(value.addressLine1, schema.input);
    },
  },
  addressLine1: {
    name: 'addressLine1',
    field: 'addressLine1',
    component: CleanInput,
    validate(value: any) {
      return createValidator(value, schema.input);
    },
  },
  city: {
    name: 'city',
    field: 'city',
    component: CleanInput,
    validate(value: any) {
      return createValidator(value, schema.input);
    },
  },
  state: {
    name: 'state',
    field: 'state',
    component: CleanInput,
    validate(value: any) {
      return createValidator(value, schema.input);
    },
  },
  zip: {
    name: 'zip',
    field: 'zip',
    component: CleanInput,
    validate(value: any) {
      return createValidator(value, schema.input);
    },
  },
  profilePicture: {
    name: 'profilePicture',
    field: 'profilePicture',
    component: CleanInput,
    validate(value: any) {
      return createValidator(value, schema.inputOptional.url());
    },
  },
  gender: {
    name: 'gender',
    field: 'gender',
    component: SelectTypeahead,
    validate(value: any) {
      return createValidator(
        typeof value === 'string' ? { gender: value } : value,
        schema.selectInput,
      );
    },
  },
  organization: {
    name: 'organization',
    field: 'organizationId',
    component: SelectTypeahead,
    validate(value: any) {
      const getValue = () => {
        if (value) {
          return typeof value !== 'object' ? { organization: value } : value;
        }
        return undefined;
      };

      return createValidator(getValue(), schema.selectInput);
    },
  },
  ehrInsurance: {
    name: 'ehrInsurance',
    field: 'ehrInsurance',
    component: CleanInput,
    validate(value: any) {
      return createValidator(value, schema.inputOptional);
    },
  },
  groupId: {
    name: 'groupId',
    field: 'groupId',
    component: CleanInput,
    validate(value: any) {
      return createValidator(value, schema.inputOptional);
    },
  },
  payerId: {
    name: 'payerId',
    field: 'payerId',
    component: CleanInput,
    validate(value: any) {
      return createValidator(value, schema.inputOptional);
    },
  },
};

export const schema = {
  input: InputSchema,
  inputOptional: InputOptionalSchema,
  phoneNumber: PhoneNumberSchema,
  phoneNumberOptional: PhoneNumberSchemaOptional,
  selectInput: SelectInputSchema,
  selectInputOptional: SelectInputOptionalSchema,
  emailInputOptional: EmailSchemaOptional,
  dob: DateOfBirthDataVerificationSchema,
  url: UrlSchema,
};
