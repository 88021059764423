import React from 'react';
import classNames from 'classnames';
import { ReactComponent as IconPersonalInfo } from '../../assets/new-icons/tab-icons/id-badg.svg';
import { ReactComponent as IconReferrals } from '../../assets/new-icons/tab-icons/referrals.svg';
import { ReactComponent as IconEncounters } from '../../assets/new-icons/tab-icons/encounters.svg';
import { ReactComponent as IconProblemsList } from '../../assets/new-icons/tab-icons/problems-list.svg';
import { ReactComponent as IconMeds } from '../../assets/new-icons/tab-icons/capsules.svg';
import { ReactComponent as IconVaccines } from '../../assets/new-icons/tab-icons/vaccines.svg';
import { ReactComponent as IconVitals } from '../../assets/new-icons/tab-icons/vitals.svg';
import { ReactComponent as IconResults } from '../../assets/new-icons/tab-icons/results.svg';

import './styles.less';

const TabsBar = ({ activeTab, setActiveTab }: any) => {
  return (
    <div className="tabs-bar gray-bg">
      <div className="tabs-wrapper">
        <button
          id="personalInfo"
          className={classNames('tab', { active: activeTab.personalInfo })}
          onClick={() => setActiveTab('personalInfo')}
        >
          <IconPersonalInfo />
          Personal info
        </button>
        <button
          id="referralsOrders"
          className={classNames('tab', { active: activeTab.referrals })}
          onClick={() => setActiveTab('referrals')}
        >
          <IconReferrals />
          Referrals/Orders
        </button>
        <button
          id="encounters"
          className={classNames('tab', { active: activeTab.appointments })}
          onClick={() => setActiveTab('appointments')}
        >
          <IconEncounters />
          Encounters
        </button>
        <button
          id="problemList"
          className={classNames('tab', { active: activeTab.problemList })}
          onClick={() => setActiveTab('problemList')}
        >
          <IconProblemsList />
          Problem list
        </button>
        <button id="meds" className="tab disabled" onClick={() => void 0}>
          <IconMeds />
          Meds
        </button>
        <button id="vaccines" className="tab disabled" onClick={() => void 0}>
          <IconVaccines />
          Vaccines
        </button>
        <button id="vitals" className="tab disabled" onClick={() => void 0}>
          <IconVitals />
          Vitals
        </button>
        <button id="results" className="tab disabled" onClick={() => void 0}>
          <IconResults />
          Results
        </button>
      </div>
    </div>
  );
};

export default TabsBar;
