import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import AppointmentModal from '../../components-v2/appointment-modal';
import useApi from '../../hooks/useApi';
import WithApiData from '../../hoks/withApiData';
import { useUserData } from '../../hooks/useUserData';
import CustomToolbar from '../../components-v2/custom-toolbar';

import './index.less';

const localizer = momentLocalizer(moment);

const Calendar = () => {
  const {
    isAdmin,
    npi: userNpi,
    organization: { id: organizationId },
  } = useUserData();

  const [providers, setProviders] = useState<any>([]);
  const [patients, setPatients] = useState<any>([]);
  const [selectedProvider, setSelectedProvider] = useState<any>();
  const [appointmentsModalVisibility, setAppointmentsModalVisibility] = useState<any>(null);
  const [providerSlots, setProviderSlots] = useState<any>([]);

  const api = useApi();

  const { formats } = useMemo(
    () => ({
      formats: {
        dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
          `${localizer.format(start, 'MM/DD/YYYY', culture)} - ${localizer.format(
            end,
            'MM/DD/YYYY',
            culture,
          )}`,
      },
    }),
    [],
  );

  useEffect(() => {
    getProviders();
    getPatients();
  }, []);

  useEffect(() => {
    if (selectedProvider) {
      getProviderAvailability();
    }
  }, [selectedProvider]);

  const getProviderAvailability = async (date?: Date) => {
    const provider = providers.find(
      (provider: { npi: string }) => provider.npi === selectedProvider,
    );

    const locationId = provider.clinics[0] && provider.clinics[0]?.location?.id;
    if (!locationId) return;

    const providerSlots = await api.getProviderAvailability({
      id: provider.id,
      locationId,
      startDate: moment(date || new Date())
        .startOf('week')
        .toDate(),
    });

    setProviderSlots(providerSlots);
  };

  const getProviders = async (search?: string) => {
    const providers = await api.getProviders({ search });

    setProviders(providers.data);

    if (providers.data.length) {
      setSelectedProvider(isAdmin ? providers.data[0].npi : userNpi);
    }
  };

  const getPatients = async (search?: string) => {
    const patients = await api.getPatients({ offset: 0, limit: 10, organizationId, search }); // TODO: can't implement pagination. Add search typeahead

    setPatients(patients.data);
  };

  const normalizeEvents = () => {
    return providerSlots.map(
      (slot: {
        startTime: any;
        endTime: any;
        id: number;
        reasonForVisit: string;
        provider: any;
        patient: any;
      }) => {
        return {
          id: slot.id,
          start: new Date(slot.startTime),
          end: new Date(slot.endTime),
          title: customAgenda(slot),
          reasonForVisit: slot.reasonForVisit,
          npi: slot.provider?.npi,
          patientId: slot.patient?.id,
          provider: slot.provider,
          patient: slot.patient,
        };
      },
    );
  };

  const eventStyleGetter = (event: any, start: any, end: any, isSelected: any) => {
    let style = { background: '#8e8c97' };

    if (start.getTime() < new Date().getTime()) {
      // @ts-ignore
      style = { ...style, opacity: '0.5', cursor: 'default' };
    }

    return { style };
  };

  const onSelectEvent = (event: any) => {
    if (event.start.getTime() < new Date().getTime()) return;
    setAppointmentsModalVisibility({ ...event, startTime: event.start });
  };

  const customAgenda = (slot: any) => {
    return (
      <div
        onClick={() =>
          onSelectEvent({
            id: slot.id,
            start: new Date(slot.startTime),
            end: new Date(slot.endTime),
            reasonForVisit: slot.reasonForVisit,
            npi: slot.provider?.npi,
            patientId: slot.patient?.id,
            provider: slot.provider,
            patient: slot.patient,
          })
        }
        className={`custom-agenda-title custom-agenda-title--${
          new Date(slot.startTime).getTime() < new Date().getTime() ? 'disabled' : null
        }`}
      >
        <span>Reason: {slot.reasonForVisit} </span>
        <span>
          Provider: {slot.provider?.firstName} {slot.provider?.lastName}
        </span>
        <span>
          Patient: {slot.patient?.firstName} {slot.patient?.lastName}
        </span>
      </div>
    );
  };

  return (
    <div className="calendar-wrapper">
      {appointmentsModalVisibility ? (
        <AppointmentModal
          updateSlots={getProviderAvailability}
          modalVisibility={appointmentsModalVisibility}
          closeModal={() => setAppointmentsModalVisibility(null)}
          providers={providers}
          patients={patients}
          providerSlots={providerSlots}
          defaultSelectedProvider={selectedProvider}
          getPatients={getPatients}
          getProviders={getProviders}
        />
      ) : null}
      <div className="calendar-controls-container">
        <div>
          <button
            onClick={() => {
              setAppointmentsModalVisibility({});
            }}
            className="btn btn-primary"
            style={{ marginBottom: 10 }}
          >
            Create Appointment
          </button>

          {isAdmin && (
            <select
              value={selectedProvider}
              onChange={(e) => {
                setSelectedProvider(e.target.value);
              }}
            >
              {providers
                .filter((provider: any) => provider.clinics.length)
                .map((provider: any) => (
                  <option key={provider.npi} value={provider.npi}>
                    {provider.firstName} {provider.lastName}
                  </option>
                ))}
            </select>
          )}
        </div>
      </div>
      <BigCalendar
        localizer={localizer}
        events={normalizeEvents()}
        views={['week', 'agenda']}
        defaultView="week"
        onSelectEvent={onSelectEvent}
        defaultDate={new Date()}
        popup
        min={new Date(0, 0, 0, 6)}
        max={new Date(0, 0, 0, 18)}
        onNavigate={(date: Date) => getProviderAvailability(date)}
        eventPropGetter={eventStyleGetter}
        formats={formats}
        components={{ toolbar: CustomToolbar }}
      />
    </div>
  );
};
export default WithApiData(Calendar);
