import React, { useState, useEffect } from 'react';
import { Button, Table, Form } from 'react-bootstrap';
import { Toast } from '@getvim/atomic-ui';
import { Box, TextareaAutosize } from '@mui/material';
import { ReactComponent as IconTrash } from '../../assets/icons/trash.svg';
import { AppointmentType, TaxonomyType, AssessmentType, Taxonomy, MetadataType } from '../../types';
import useApi from '../../hooks/useApi';
import Loader from '../loader';
import { CleanInput } from '../clean-input';
import { SelectTypeahead } from '../select-typeahead';
import Formatter from '../../utils/formatter';

import './styles.less';

const { ToastTypes, createToast } = Toast;

const PLAN_NOTES_METADATA_KEY = 'plans_notes';

export const AssessmentCPT = ({
  selectedAppointment,
  type,
  isApptLocked,
  onChange,
}: {
  selectedAppointment: AppointmentType;
  type: TaxonomyType;
  isApptLocked: boolean;
  onChange?: (metadata: MetadataType[]) => void;
}) => {
  const withGeneralNotes = Boolean(onChange);

  const [isLoading, setLoading] = useState<boolean>(false);

  const [selected, setSelected] = useState<Taxonomy[]>([]);
  const [notes, setNotes] = useState<string>('');
  const [generalNotes, setGeneralNotes] = useState<string>('');

  const [cptCodes, setCptCodes] = useState<Taxonomy[]>([]);
  const [assessments, setAssessments] = useState<AssessmentType[]>([]);

  const api = useApi();

  useEffect(() => {
    getAssessments();
  }, [selectedAppointment]);

  useEffect(() => {
    getTaxonomiesList();
  }, [assessments]);

  const getAssessments = async () => {
    if (selectedAppointment) {
      try {
        setLoading(true);
        const appointment = await api.getAppointment({ id: selectedAppointment.id });

        const filteredAssessments = appointment.assessment.reduce(
          (filtered: AssessmentType[], option: AssessmentType) => {
            if (option.taxonomy.type === type) {
              filtered.push(option);
            }
            return filtered;
          },
          [],
        );

        if (withGeneralNotes) {
          const generalNotes = parseGeneralNotesMetadata(appointment.metadata);
          setGeneralNotes(generalNotes);
        }

        setAssessments(filteredAssessments);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    }
  };

  const getTaxonomiesList = async () => {
    try {
      setLoading(true);
      const { data } = await api.getTaxonomiesList({ type });
      const filteredData = data.filter(
        (taxonomy: { id: number }) =>
          !assessments.find((assessment) => assessment.taxonomy.id === taxonomy.id),
      );

      setCptCodes(filteredData);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const deleteCptCode = async (item: AssessmentType) => {
    setLoading(true);

    const assessmentList = assessments.filter((assessment) => assessment.note?.id !== item.note.id);
    await api.removeAssessmentFromAppointment({
      noteId: item.note.id,
      appointmentId: selectedAppointment.id,
    });

    cptCodes.push(item.taxonomy);
    setCptCodes([...cptCodes]);
    setAssessments([...assessmentList]);
    setLoading(false);
  };

  const addCptCodes = async ({ taxonomy }: { taxonomy: Taxonomy }) => {
    try {
      setLoading(true);
      const itemToAdd = { taxonomy, note: { notes, id: -1 } };
      const { noteId } = await api.addAssessmentToAppointment({
        appointmentId: selectedAppointment.id,
        taxonomyId: itemToAdd.taxonomy.id,
        notes: itemToAdd.note.notes?.trim(),
      });
      itemToAdd.note.id = noteId;
      const cptCodesList = cptCodes.filter((item) => item.id !== taxonomy.id);
      assessments.push(itemToAdd);
      setCptCodes([...cptCodesList]);
      setAssessments(assessments);
      setSelected([]);
      setNotes('');
    } catch (err: any) {
      createToast({
        title: `Oops, error!`,
        message: err.error?.message[0],
        type: ToastTypes.ERROR,
        html: true,
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };

  const parseGeneralNotesMetadata = (metadata: MetadataType[]) => {
    return metadata?.find((item) => item.key === PLAN_NOTES_METADATA_KEY)?.value ?? '';
  };

  const handleGeneralNotesChange = (event) => {
    if (!onChange) return;

    const value = event.target?.value;
    setGeneralNotes(value);
    onChange([{ key: PLAN_NOTES_METADATA_KEY, value }]);
  };

  return (
    <div className="assessment">
      {isLoading && <Loader />}
      <div className="billing-info-block">
        <div className="assessment__select-wrapper">
          <SelectTypeahead
            description={`* ${type.toUpperCase()}`}
            className="select-container"
            labelKey="label"
            filterBy={['label', 'description']}
            options={cptCodes}
            selected={selected}
            onChange={(value) => {
              setSelected(value);
            }}
            disabled={isApptLocked}
            // @ts-ignore
            renderMenuItemChildren={(label, option: Taxonomy) => (
              <div data-code={option.label}>
                {option.label}
                <div className="small-description" data-code={option.description}>
                  <small>{Formatter.toSentenceCase(option.description)}</small>
                </div>
              </div>
            )}
          />

          <CleanInput
            inputType
            disabled={isApptLocked}
            className="clean-input-container"
            labelKey="notes"
            value={notes}
            description="Notes"
            onChange={(e: { target: { value: any } }) => {
              setNotes(e.target.value?.replace(/^\s*/, ''));
            }}
          />

          <Button
            disabled={!selected.length || isApptLocked}
            id="add-icd-btn"
            className="assessment__add-icd-btn"
            onClick={() => {
              addCptCodes({ taxonomy: selected[0] });
            }}
          >
            <i className="icon-plus-fat i-va-fix-2" />
            &nbsp;
            <span>Add</span>
          </Button>
        </div>

        {withGeneralNotes && (
          <div>
            <Box
              className="text-area-wrapper no-padding"
              display="flex"
              flexDirection="column"
              flexGrow={1}
            >
              <div className="title">
                <span>General notes</span>
              </div>
              <Box>
                <TextareaAutosize
                  data-test-id="general-notes-text"
                  style={{ width: '100%', resize: 'none' }}
                  maxRows={2}
                  minRows={2}
                  value={generalNotes}
                  disabled={isApptLocked}
                  onChange={handleGeneralNotesChange}
                />
              </Box>
            </Box>
          </div>
        )}

        <div className="table-wrapper">
          <Table borderless hover>
            <thead>
              <tr>
                <th className="label-column">{type.toUpperCase()}</th>
                <th className="description-column">Description</th>
                <th>Notes</th>
                <th className="drop-icon-column" />
              </tr>
            </thead>
            <tbody>
              {assessments.map((item: any) => {
                return (
                  <>
                    <tr key={item.taxonomy.id} className="main-row">
                      <td className="label-column">{item.taxonomy.label}</td>
                      <td className="description-column" title={item.taxonomy.description}>
                        {Formatter.toSentenceCase(item.taxonomy.description)}
                      </td>
                      <td className="" title={item.note?.notes}>
                        {item.note?.notes}
                      </td>
                      <td>
                        <IconTrash
                          className={`trash-icon${isApptLocked ? ' disabled' : ''}`}
                          onClick={() => !isApptLocked && deleteCptCode(item)}
                        />
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};
