import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Toast } from '@getvim/atomic-ui';
import { Button } from 'react-bootstrap';
import { useFeatureFlag, Team } from '@getvim/feature-flags-react';
import { enableLockUnlockApptFF } from '../../utils/featureFlagClient';
import { ReactComponent as IconTrash } from '../../assets/icons/trash.svg';

import AppointmentModal from '../appointment-modal';
import ConfirmDeleteModal from '../confirm-delete-modal';
import { ClinicalNotesModal } from '../clinical-notes-modal';
import { AppointmentLockStatusChip } from '../appointment-status-chip';
import useApi from '../../hooks/useApi';
import { useUserData } from '../../hooks/useUserData';

import './styles.less';

const { ToastTypes, createToast } = Toast;

const WARN_MESSAGE_ON_DELETE_APPTS = (
  <>
    Do you really want to delete
    <b>
      <i> all patient's appointments</i>
    </b>
    ? Related appointment data will be also deleted: Referrals, Orders, Notes.
  </>
);

export const Appointments = ({ profileData }: any) => {
  const [eventsList, setEventList] = useState<{ future: null | any; past: null | any }>({
    future: null,
    past: null,
  });
  const [FilterModalOpen, setFilterModalOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState<any>(false);
  const [appointmentsModalVisibility, setAppointmentsModalVisibility] = useState<any>(null);
  const [deleteApptsModalVisibility, setDeleteApptsModalVisibility] = useState<any>(null);
  const [providers, setProviders] = useState<any>([]);
  const [selectedProvider, setSelectedProvider] = useState<any>();
  const [providerSlots, setProviderSlots] = useState<any>([]);

  const [enableDeleteApptsForNonAdmin] = useFeatureFlag({
    flagName: 'mock_ehr.enable_delete_appts_for_non_admins',
    defaultValue: false,
    team: Team.Interfaces,
  });

  const [enableLockUnlockAppt] = useFeatureFlag({
    flagName: enableLockUnlockApptFF,
    defaultValue: false,
    team: Team.Interfaces,
  });

  const user = useUserData();
  const api = useApi();

  useEffect(() => {
    getProviders();
    getPatientAppointments();
  }, []);

  useEffect(() => {
    if (selectedProvider) {
      getProviderAvailability();
    }
  }, [selectedProvider]);

  const getPatientAppointments = async () => {
    const appointments = await api.getPatientAppointments({ id: profileData.id });

    sortAppointments(appointments);
  };

  const getProviders = async (search?: string) => {
    const providers = await api.getProviders({ search });

    setProviders(providers.data);
    if (providers.data.length) {
      setSelectedProvider(user.isAdmin ? providers.data[0].npi : user.npi);
    }
  };

  const getProviderAvailability = async (date?: Date) => {
    const provider = providers.find(
      (provider: { npi: string }) => provider.npi === selectedProvider,
    );
    const locationId = provider.clinics[0]?.location?.id;
    if (!locationId) return;

    const providerSlots = await api.getProviderAvailability({
      id: provider.id,
      locationId,
      startDate: moment(date || new Date())
        .startOf('week')
        .toDate(),
    });

    setProviderSlots(providerSlots);
  };

  const deletePatientAppointments = async () => {
    try {
      await api.deletePatientAppointments(profileData.id);
    } catch (err: any) {
      createToast({
        title: 'Error!',
        message: err.message,
        type: ToastTypes.ERROR,
        html: true,
      });
    }
  };

  const handleDeleteAppointments = async () => {
    setDeleteApptsModalVisibility(null);
    await deletePatientAppointments();
    getPatientAppointments();
  };

  const openModal = (appointment: {
    id: number;
    startDate: string;
    externalId?: string;
    provider: { firstName: string; lastName: string; suffix: string };
  }) => {
    setFilterModalOpen(true);
    // @ts-ignore
    setSelectedAppointment(appointment);
  };

  const handleApptModalClosed = async () => {
    setFilterModalOpen(!FilterModalOpen);
    setSelectedAppointment(false);
  };

  const sortAppointments = (appointments: any[]) => {
    const futureEvents: any[] = [];
    const pastEvents: any[] = [];

    appointments.forEach((el) => {
      if (Date.now() < Date.parse(el.startDate).valueOf()) {
        futureEvents.push(el);
      } else {
        pastEvents.push(el);
      }
    });

    // api returns appts in ASC order, as it is needed for Future appts
    const pastSortedDesc = [...pastEvents].sort(
      (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
    );

    setEventList({ future: futureEvents, past: pastSortedDesc });
  };

  const AppointmentsList = ({ appointments }: any) => {
    return (
      <div className="appointments__list">
        {appointments.map(
          (appointment: {
            id: number;
            startDate: string;
            provider: {
              firstName: string;
              lastName: string;
              suffix: string;
            };
            isLocked: boolean;
          }) => {
            const { provider } = appointment;
            const providerName = `${provider.firstName} ${provider.lastName}, ${provider.suffix}`;
            return (
              <div
                key={appointment.id}
                data-apptId={appointment.id}
                className="appointments__list-item"
              >
                <div className="appointments__list-item__details">
                  <div className="appointments__list-item__info">
                    <span className="appointments__list-item__date">
                      {moment(appointment.startDate).format('MMM DD, YYYY')}
                    </span>
                    <span className="appointments__list-item__summary">{providerName}</span>
                  </div>
                  {enableLockUnlockAppt && appointment.isLocked && <AppointmentLockStatusChip />}
                </div>
                <span
                  className="appointments__list-item__notes"
                  onClick={() => openModal(appointment)}
                >
                  Clinical Notes
                </span>
                <div className="appointments__list-item__line" />
              </div>
            );
          },
        )}
      </div>
    );
  };

  const { future, past } = eventsList;
  const hasSomeAppts = Boolean(future?.length || past?.length);

  const isDeleteApptsDisplayed = enableDeleteApptsForNonAdmin || user.isAdmin;

  return (
    <div className="appointments">
      {future && (
        <div className="appointments__list-wrapper">
          <div className="appointments__list-title">Future Appointment</div>
          <div className="appointments__action-buttons">
            {isDeleteApptsDisplayed && (
              <Button
                bsPrefix="btn btn-secondary"
                className="appointments__delete-all-appts-btn"
                disabled={!hasSomeAppts}
                onClick={() => setDeleteApptsModalVisibility({})}
              >
                <IconTrash className="appointments__trash-icon" />
                <span> Delete All Appointments</span>
              </Button>
            )}
            <Button
              className="appointments__new-appt-btn btn btn-primary"
              onClick={() => setAppointmentsModalVisibility({})}
            >
              <i className="icon-plus-fat i-va-fix-2" />
              &nbsp;
              <span> New Appointment</span>
            </Button>
          </div>
          <AppointmentsList appointments={future} />
        </div>
      )}
      {past && (
        <div className="appointments__list-wrapper">
          <div className="appointments__list-title">Past Appointment</div>
          <AppointmentsList appointments={past} />
        </div>
      )}
      {FilterModalOpen && (
        <ClinicalNotesModal
          modalTitle="Clinical Notes"
          patient={profileData}
          isOpen={FilterModalOpen}
          selectedAppointment={selectedAppointment}
          onClose={handleApptModalClosed}
          onApptLockToggle={getPatientAppointments}
          enableLockUnlockApptFF={enableLockUnlockAppt}
        />
      )}
      {appointmentsModalVisibility && (
        <AppointmentModal
          updateSlots={() => {
            getProviderAvailability();
            getPatientAppointments();
          }}
          modalVisibility={appointmentsModalVisibility}
          closeModal={() => setAppointmentsModalVisibility(null)}
          providers={providers}
          patients={[profileData]}
          providerSlots={providerSlots}
          defaultSelectedProvider={selectedProvider}
          getProviders={getProviders}
        />
      )}
      {isDeleteApptsDisplayed && (
        <ConfirmDeleteModal
          warnMessage={WARN_MESSAGE_ON_DELETE_APPTS}
          onConfirmed={() => handleDeleteAppointments()}
          onClose={() => setDeleteApptsModalVisibility(null)}
          isOpen={deleteApptsModalVisibility}
        />
      )}
    </div>
  );
};
