import { SelectTypeahead } from '../select-typeahead';
import { DatePickerInput } from '../date-picker-input';
import { Input } from '../input';
import { SelectInputSchema, SelectMultipleInputSchema, InputSchema } from '../../schemas';
import createValidator from '../../utils/createValidator';

export const fields = {
  reasonForVisit: {
    name: 'reasonForVisit',
    field: 'reasonForVisit',
    component: Input,
    validate(value: any) {
      return createValidator(value, schema.input);
    },
  },
  startTime: {
    name: 'startTime',
    field: 'startTime',
    component: DatePickerInput,
    validate(value: Date) {
      if (!value || value < new Date() || value.getHours() < 6 || value.getHours() > 17) {
        return 'Please select proper time';
      }
      return false;
    },
  },
  provider: {
    name: 'provider',
    field: 'provider',
    component: SelectTypeahead,
    validate(value: any) {
      return createValidator(value, schema.selectInput);
    },
  },
  patient: {
    name: 'patient',
    field: 'patient',
    component: SelectTypeahead,
    validate(value: any) {
      return createValidator(value, schema.selectInput);
    },
  },
  externalId: {
    name: 'externalId',
    field: 'externalId',
    component: Input,
  },
};

export const schema = {
  selectInput: SelectInputSchema.required(),
  input: InputSchema.required(),
  selectMultipleInput: SelectMultipleInputSchema.required(),
};
