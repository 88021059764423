import * as React from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';
import './Switch.less';

const handleClick = (event) => {
  event.stopPropagation();
};

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & {
    label?: string;
    stopPropagation?: boolean;
  }
>(({ checked, label, stopPropagation = false, id, ...props }, ref) => (
  <div className="switch-container" onClick={stopPropagation ? handleClick : undefined}>
    <SwitchPrimitives.Root className="switch-root" ref={ref} id={id} checked={checked} {...props}>
      <SwitchPrimitives.Thumb className="switch-thumb" />
    </SwitchPrimitives.Root>
    <label className="switch-label" htmlFor={id}>
      {label}
    </label>
  </div>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
