import React from 'react';

import './styles.less';

interface CleanInputProps {
  className: string;
  labelKey: string;
  description: string;
  error?: any;
  submitted?: boolean;
  onChange: (event: any) => void;
  inputType: any;
  value: any;
  disabled?: boolean;
  placeholder?: string;
  showError?: boolean;
}

export const CleanInput = React.forwardRef((props: CleanInputProps, ref) => {
  const {
    className,
    disabled,
    value,
    labelKey,
    description,
    error,
    submitted,
    onChange,
    inputType,
    placeholder,
    showError,
  } = props;

  const hasErrorToSHow = showError && error?.trim() && submitted;
  return (
    <div id={labelKey} className={className}>
      <div
        className={` ${error && submitted && 'has-error'} freetext-input freetext-input-wrapper`}
      >
        <span className={`${className} clean-input-label`}>{description}</span>
        <input
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          type={inputType}
          // @ts-ignore
          ref={ref}
          onChange={onChange}
          className="input"
        />
        {hasErrorToSHow ? (
          <span className="clean-input-label clean-error-message">{error} </span>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
});
