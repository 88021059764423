export interface Provider {
  id: string;
  fullName: string;
  gender: string;
  dob: string;
  insurer: string;
  profilePicture: string;
  organization: {
    id: number;
    alias: string;
    name: string;
  };
}
export interface CreateProviderRequest {
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  npi: string;
  phoneNumber: {
    number: string;
    countryDialCode: string;
  };
  onBoarded: boolean;
  suffix: 'MD';
  gender: 'Male' | 'Female';
}

export const PROVIDER_ROLES = {
  PROVIDER: {
    label: 'Provider',
    value: 'PROVIDER',
  },
  DATA_ACCESS: {
    label: 'Data access',
    value: 'DATA_ACCESS',
  },
  ADMIN: {
    label: 'Admin',
    value: 'ADMIN',
  },
};
