import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import * as uuid from 'uuid';
import Search from '../../components-v2/search';
import UserHeaderInfo from '../../components-v2/user-header-info';
import WithApiData from '../../hoks/withApiData';
import { useUserData } from '../../hooks/useUserData';

import './styles.less';

const EVENTS_LIST = [
  {
    start: {
      dateTime: '2021-12-11T11:22:48.000',
    },
    id: uuid.v1(),
  },
  {
    start: {
      dateTime: '2021-07-07T11:22:48.000',
    },
    id: uuid.v1(),
  },
  {
    start: {
      dateTime: '2021-08-08T11:22:48.000',
    },
    id: uuid.v1(),
  },
  {
    start: {
      dateTime: '2021-09-09T11:22:48.000',
    },
    id: uuid.v1(),
  },
];

const Dashboard = () => {
  const user = useUserData();
  return (
    <div className="dashboard">
      <div className="header-content">
        <Search />
        <UserHeaderInfo name={user.firstName} />
      </div>
      <h1 className="dashboard-title">Welcome Dr. {user.firstName}! (V2)</h1>
      <div className="open-calendar">
        <Link to="/calendar" className="btn">
          Open calendar
        </Link>
      </div>
      <div className="dashboard-content dashboard-top-content">
        <div className="specification-column">
          <ul>
            <li className="list-header">Appointments</li>
            {EVENTS_LIST.map((event) => (
              <li key={event.id}>{moment(event.start.dateTime).format('MMM DD, YYYY')}</li>
            ))}
          </ul>
        </div>
        <div className="specification-column">
          <ul>
            <li className="list-header">Medical Billing</li>
            <li>
              <div className="item-with-counter">
                Claims Received <div className="counter">15</div>
              </div>
            </li>
            <li>
              <div className="item-with-counter">
                Claims in Progress <div className="counter">3</div>
              </div>
            </li>
            <li>
              <div className="item-with-counter">
                Claims Requiring Action <div className="counter">-</div>
              </div>
            </li>
          </ul>
          <div className="divider" />
        </div>
        <div className="specification-column">
          <ul>
            <li className="list-header">Messages</li>
            <li>&nbsp;</li>
            <li>&nbsp;</li>
            <li>&nbsp;</li>
            <li>&nbsp;</li>
          </ul>
        </div>
      </div>
      <div className="dashboard-content dashboard-bottom-content">
        <div className="specification-column">
          <ul>
            <li className="list-header">Usage</li>
            <li>
              <div className="item-with-counter">
                Faxes <div className="counter">100/150</div>
              </div>
            </li>
            <li>
              <div className="item-with-counter">
                Statements Malled <div className="counter">35</div>
              </div>
            </li>
          </ul>
          <div className="divider" />
        </div>
      </div>
    </div>
  );
};

export default WithApiData(Dashboard);
