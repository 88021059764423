import { useAuth0 } from '@auth0/auth0-react';
import { Tooltip } from '@getvim/atomic-ui';
import React, { useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';

import { EhrSDKContext } from '../../SdkWrapper';
import {
  Calendar,
  IconApps,
  IconClaims,
  IconFile,
  IconLogout,
  IconOrganization,
  IconPatient,
  IconProfile,
  IconReports,
  IconSupport,
  IconVimSettings,
  IconWallet,
} from '../../assets/icons';
import { useUserData } from '../../hooks/useUserData';
import { UserRole } from '../../types/user';
import { removeItem } from '../../utils/local-storage';
import { useRoleAccess } from '../../utils/roles';
import './styles.less';

type Props = {
  openVimSettings: () => void;
  isV2: boolean;
};

const SidebarMenu: React.FC<Props> = ({ isV2, openVimSettings }) => {
  const { isSdkLoaded } = useContext(EhrSDKContext);
  const { isAuthenticated, logout } = useAuth0();
  const user = useUserData();
  const roleAccess = useRoleAccess();

  const organizationAlias = user?.organization?.alias;

  const onLogout = useCallback(() => {
    removeItem('access_token');
    removeItem('user');
    logout({ returnTo: window.location.origin });
  }, [logout]);

  if (!isAuthenticated) {
    return null;
  }

  const V2_PREFIX = isV2 ? '/v2' : '';

  return (
    <>
      <ul className="sidebar-menu">
        {user.notFound ? null : (
          <>
            <li>
              <Link id="calendar" to={`${V2_PREFIX}/${organizationAlias}/calendar/`}>
                <Calendar />
                <span>Calendar</span>
              </Link>
            </li>
            <li>
              <Link id="patients" to={`${V2_PREFIX}/${organizationAlias}/patients`}>
                <IconPatient />
                <span>Patients</span>
              </Link>
            </li>
            {roleAccess([UserRole.PROVIDER]) && (
              <li>
                <Link id="claims" to={`${V2_PREFIX}/${organizationAlias}/`}>
                  <IconClaims />
                  <span>Claims</span>
                </Link>
              </li>
            )}
            {roleAccess([UserRole.PROVIDER]) && (
              <li>
                <Link id="financial" to={`${V2_PREFIX}/${organizationAlias}/`}>
                  <IconWallet />
                  <span>Financial</span>
                </Link>
              </li>
            )}
            <li>
              <Link id="reports" to={`${V2_PREFIX}/${organizationAlias}/`}>
                <IconReports />
                <span>Reports</span>
              </Link>
            </li>
            {roleAccess([UserRole.PROVIDER]) && (
              <li>
                <Link id="apps" to={`${V2_PREFIX}/${organizationAlias}/`}>
                  <IconApps />
                  <span>Apps</span>
                </Link>
              </li>
            )}
            {roleAccess([UserRole.PROVIDER]) && (
              <li>
                <Link id="support" to={`${V2_PREFIX}/${organizationAlias}/`}>
                  <IconSupport />
                  <span>Support</span>
                </Link>
              </li>
            )}
            {roleAccess([UserRole.ADMIN]) && (
              <li>
                <Link id="organizations" to={`${V2_PREFIX}/${organizationAlias}/organizations/`}>
                  <IconOrganization />
                  <span>Organizations</span>
                </Link>
              </li>
            )}
            {roleAccess([UserRole.ADMIN]) && (
              <li>
                <Link
                  id="providers"
                  className="smallIcon"
                  to={`${V2_PREFIX}/${organizationAlias}/providers`}
                >
                  <IconProfile />
                  <span>Providers</span>
                </Link>
              </li>
            )}
            {roleAccess([UserRole.DATA_ACCESS]) && (
              <li>
                <Link
                  className="smallIcon"
                  to={`${V2_PREFIX}/${organizationAlias}/data-verification/`}
                >
                  <IconFile />
                  <span>Data Verification</span>
                </Link>
              </li>
            )}
            {roleAccess([UserRole.ADMIN]) && (
              <li>
                <Link className="smallIcon" to={`${V2_PREFIX}/${organizationAlias}/audit`}>
                  <IconProfile />
                  <span>Audit</span>
                </Link>
              </li>
            )}
            {isSdkLoaded && (
              <li>
                <a onClick={openVimSettings}>
                  <IconVimSettings />
                  <span>Vim Settings</span>
                </a>
              </li>
            )}
          </>
        )}

        <li>
          <a className="smallIcon" onClick={onLogout}>
            <IconLogout />
            <Tooltip tooltipContent="logouttttt" bgColor="dark">
              <span>Logout</span>
            </Tooltip>
          </a>
        </li>
      </ul>
    </>
  );
};

export default SidebarMenu;
