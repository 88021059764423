import React, { forwardRef } from 'react';

import './styles.less';

interface RadioButtonsProps {
  description: string;
  options: any[];
  selected?: string;
  ref?: any;
  onChange: (value: any) => void;
}

export const RadioButtons = forwardRef((props: RadioButtonsProps, ref: any) => {
  const { description, options, selected, onChange } = props;

  return (
    <div>
      {description}
      <div className="radio-buttons-container">
        {options.map(({ label }) => {
          const isSelected = selected?.toLowerCase() === label.toLowerCase();

          return (
            <label className={`radio-button-label${isSelected ? ' checked' : ''}`}>
              <input
                ref={ref}
                className="radio-button"
                type="radio"
                value={label}
                checked={isSelected}
                onChange={onChange}
              />
              {label}
            </label>
          );
        })}
      </div>
    </div>
  );
});
