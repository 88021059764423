import React, { ChangeEvent, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './styles.less';
import { debounce } from 'lodash-es';
import useApi from '../../hooks/useApi';
import { Keycodes, Patient } from '../../types';
import { useUserData } from '../../hooks/useUserData';

const debouncedFetchData = debounce((func, value) => {
  func(value);
}, 1000);

const displayFullName = (patient: { firstName?: string; lastName?: string }) => {
  const { firstName, lastName } = patient;
  let fullName = '';
  if (firstName) {
    fullName += `${firstName} `;
  }
  if (lastName) {
    fullName += lastName;
  }
  return fullName;
};

interface FilteredData extends Patient {
  isHighlighted?: boolean;
}

interface SearchProps {
  filter?: string;
  setFilter?: (value: string) => void;
}

const Search = (searchProps: SearchProps) => {
  const api = useApi();
  const user = useUserData();
  const organizationId = user.organization.id;
  const history = useHistory();

  const data = async (value) => {
    const patients = await api.getPatients({
      offset: 0,
      limit: 1000,
      search: value,
      organizationId: user.isAdmin ? undefined : organizationId,
    });
    const patientData = patients.data as FilteredData[];
    setFetching(false);
    // set up first element highlighted by default
    if (patientData.length) {
      patientData[0].isHighlighted = true;
    }
    setFilteredData(patientData);
  };

  let filter;
  let setFilter;
  if (!searchProps.filter && !searchProps.setFilter) {
    [filter, setFilter] = useState<string>('');
  } else {
    filter = searchProps.filter;
    setFilter = searchProps.setFilter;
  }
  const [filteredData, setFilteredData] = useState<FilteredData[]>([]);
  const [isFetching, setFetching] = useState<boolean>(false);
  const [searchFieldActive, setIsSearchFieldActive] = useState<boolean>(false);

  const handleChange = (value: string) => {
    setIsSearchFieldActive(true);
    setFilter && setFilter(value);
  };

  useEffect(() => {
    if (filter) {
      setFetching(true);
      debouncedFetchData(data, filter);
    } else {
      setFilteredData([]);
    }
  }, [filter]);

  const keydownListener = (keydownEvent) => {
    const { which: keyCode } = keydownEvent;
    if ([Keycodes.enter, Keycodes.downArrow, Keycodes.upArrow].includes(keyCode)) {
      keydownEvent.preventDefault();
      if (filteredData.length > 0) {
        const patientIndex = filteredData.findIndex((item) => item.isHighlighted);
        switch (keyCode) {
          case Keycodes.upArrow: {
            if (filteredData[patientIndex - 1]) {
              filteredData[patientIndex].isHighlighted = false;
              filteredData[patientIndex - 1].isHighlighted = true;
              setFilteredData([...filteredData]);
            }
            break;
          }
          case Keycodes.downArrow: {
            if (filteredData[patientIndex + 1]) {
              filteredData[patientIndex].isHighlighted = false;
              filteredData[patientIndex + 1].isHighlighted = true;
              setFilteredData([...filteredData]);
            }
            break;
          }
          case Keycodes.enter: {
            history.push(`/patient/${filteredData[patientIndex].id}`);
            break;
          }
        }
      }
    }
  };

  return (
    <div className="search">
      <form className="search-form-v2">
        <input
          value={filter}
          type="search"
          placeholder="Search patient in the EHR"
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event.target.value)}
          onBlur={() => {
            setTimeout(() => setIsSearchFieldActive(false), 500);
          }}
          onKeyDown={keydownListener}
        />
      </form>
      {!isFetching && filter && searchFieldActive ? (
        <div className="search-results">
          {Array.isArray(filteredData) && filteredData.length ? (
            filteredData.map((item) => {
              let className = 'search-item';
              if (item.isHighlighted) {
                className += ' highlighted';
              }
              return (
                <Link key={item.id} to={`/patient/${item.id}`} className={className}>
                  {displayFullName(item)}
                </Link>
              );
            })
          ) : (
            <span className="search-item">Nothing found</span>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default Search;
