import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Dashboard from './pages-v2/dashboard';
import Patients from './pages-v2/patients';
import Providers from './pages-v2/providers';
import PatientProfile from './pages-v2/patient-profile';
import Organizations from './pages-v2/organizations';
import Login from './pages-v2/login';
import IdpInitiatedLogin from './pages-v2/idp-initiated-login';
import Calendar from './pages-v2/calendar';
import Audit from './pages-v2/audit';
import DataVerification from './pages-v2/data-verification';
import PrivateRoute from './components-v2/private-route';
import { UserRole } from './types/user';

const RoutesV2Temporary = (props: { alias }) => {
  const { alias } = props;

  const V2_PREFIX = '/v2';

  return (
    <>
      <Route exact path={`${V2_PREFIX}/idp-initiated-login`} component={IdpInitiatedLogin} />
      <Route exact path={`${V2_PREFIX}/login`} component={Login} />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/:organizationAlias`}
        render={() => {
          return <Dashboard />;
        }}
      />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/:organizationAlias/providers`}
        roles={[UserRole.ADMIN]}
        render={() => <Providers {...props} />}
      />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/:organizationAlias/patients`}
        render={() => <Patients />}
      />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/:organizationAlias/audit`}
        roles={[UserRole.ADMIN]}
        render={() => <Audit />}
      />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/:organizationAlias/data-verification`}
        roles={[UserRole.ADMIN, UserRole.DATA_ACCESS]}
        render={() => <DataVerification />}
      />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/:organizationAlias/calendar`}
        render={() => <Calendar />}
      />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/:organizationAlias/organizations`}
        roles={[UserRole.ADMIN]}
        render={() => <Organizations {...props} />}
      />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/:organizationAlias/patient/:id`}
        render={(res: { match: { params: { id: any } } }) => {
          const { id } = res.match.params;

          return <PatientProfile patientId={id} />;
        }}
      />
    </>
  );
};

export default RoutesV2Temporary;
