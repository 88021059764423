import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import './styles.less';

const DATE_FORMAT = 'MMMM d, yyyy h:mm aa';

export const DatePickerInput = forwardRef((props: any, ref: any) => {
  const { error, submitted, label } = props;

  return (
    <div className={`${error && submitted && 'has-error'} date`} ref={ref}>
      <span className="date-title">{label}</span>
      <DatePicker
        {...props}
        showTimeSelect
        minTime={moment().set('hour', 6).set('minute', 0).toDate()}
        maxTime={moment().set('hour', 17).set('minute', 30).toDate()}
        minDate={new Date()}
        dateFormat={DATE_FORMAT}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        autoComplete="off"
      />
      {error && <span className="date-error">{error}</span>}
    </div>
  );
});
