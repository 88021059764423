import * as yup from 'yup';
import { parse, isDate } from 'date-fns';

yup.setLocale({
  // use constant translation keys for messages without values
  mixed: {
    required: ' ',
  },
  string: {
    url: 'Invalid url',
  },
});

export const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

export const SelectInputSchema = yup.object().required();

export const SelectInputOptionalSchema = yup.object().optional();

export const SelectMultipleInputSchema = yup.array().required();

export const InputSchema = yup.string().required();

export const InputOptionalSchema = yup.string().nullable();
export const InputNpiSchema = yup
  .string()
  .required()
  .min(10, 'Must be 10 characters')
  .max(10, 'Must be 10 characters');

export const DateInputSchema = yup.date().min(new Date());

export const PhoneNumberSchema = yup.object().shape({
  number: yup.string().length(10, 'Must be 10 characters').required(),
  countryDialingCode: yup.string().required(),
});

export const PhoneNumberSchemaOptional = yup.object().shape({
  number: yup.string().length(10, 'Must be 10 characters').optional(),
  countryDialingCode: yup.string().optional(),
});

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))$/;
const INVALID_EMAIL_ERROR = 'Please, enter a valid email. Example: example@email.com';

export const EmailSchema = yup.string().matches(EMAIL_REGEX, INVALID_EMAIL_ERROR).required();

export const EmailSchemaOptional = yup
  .string()
  .optional()
  .nullable()
  .matches(EMAIL_REGEX, { message: INVALID_EMAIL_ERROR, excludeEmptyString: true });

export const UrlSchema = yup.string().required().url();

function parseDateString(value, originalValue) {
  return isDate(originalValue) ? originalValue : parse(originalValue, 'MM/dd/yyyy', new Date());
}

export const DateOfBirthSchema = yup.date().transform(parseDateString).max(new Date());

/**
 * https://auth0.com/docs/authenticate/database-connections/password-strength#password-policies
 */
export const PasswordSchema = yup
  .string()
  .required(' ') // empty message which is not equal to null - just to highlight;
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])+[A-Za-z\d@$!%*?&#]{8,}$/,
    'Must contain at least 8 characters including a lower-case letter, an upper-case letter, a number and a special character (such as !@#$%^&*).',
  );

// data-verification page schema's

export const InputDataVerificationSchema = yup
  .string()
  .nullable()
  .min(3, 'Must be at least 3 characters');

export const EmailDataVerificationSchema = yup
  .string()
  .matches(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))$/,
    'Please, enter a valid email',
  )
  .nullable();

export const DateOfBirthDataVerificationSchema = yup
  .date()
  .transform(parseDateString)
  .max(new Date(), 'Date should be in the past')
  .typeError('Date format should be mm/dd/yyyy');
